import React, { useState } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton
} from '@material-ui/core'

import { ExModalDelete } from 'components'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import { getTypeBanner, numberFormat } from 'helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  updateButton: {
    marginRight: theme.spacing(1)
  }
}))

const ExTable = props => {
  const { 
    className, 
    items, 
    page, 
    pages, 
    limit, 
    onChangePage, 
    onChangeRowsPerPage, 
    onDeleteData, 
    onDetailData, 
    ...rest 
  } = props

  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const classes = useStyles()

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleOpenModal = (id) => {
    setSelectedId(id)
    setModal(true)
  }

  const handleDeleteModal = () => {
    onDeleteData(selectedId)
    setModal(false)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Tipe</TableCell>
                  <TableCell>Kode</TableCell>
                  <TableCell>Nilai</TableCell>
                  <TableCell>Dari</TableCell>
                  <TableCell>Sampai</TableCell>
                  <TableCell>Maks / Agen</TableCell>
                  <TableCell>Min Harga</TableCell>
                  <TableCell>Jumlah</TableCell>
                  <TableCell>Digunakan</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={item.id}
                  >
                    <TableCell>{((page*limit)-limit) + (index+1)}</TableCell>
                    <TableCell>{getTypeBanner(item.group)}</TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{numberFormat(item.value, 'Rp. ')}</TableCell>
                    <TableCell>{moment(item.start).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{moment(item.end).format('DD/MM/YYYY')}</TableCell>
                    <TableCell>{item.max_use}</TableCell>
                    <TableCell>{numberFormat(item.min_purchase, 'Rp. ')}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.used}</TableCell>
                    <TableCell>
                      <IconButton
                        color="inherit"
                        onClick={() => onDetailData(item.code)}
                      >
                        <MenuOpenIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => handleOpenModal(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pages * limit}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page-1}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      
      <ExModalDelete 
        open={modal} 
        onClose={handleCloseModal} 
        onDelete={handleDeleteModal}
      />
    </Card>
  )
}

ExTable.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDeleteData: PropTypes.func.isRequired,
  onDetailData: PropTypes.func.isRequired
}

export default ExTable
