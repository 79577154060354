
function requestAPI(page, limit, name, token) {
  return fetch(`${process.env.REACT_APP_ADMIN_URL}voucher?page=${page}&limit=${limit}&name=${name}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((res) => res.json())
}

export default function actionListVoucher(page, limit, name) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'LIST_VOUCHER_BEGIN'
    })

    try {
      const token = await getState().auth.authenticate.token
      const result = await requestAPI(page, limit, name, token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'LIST_VOUCHER_SUCCESS',
        page: result.data.page,
        pages: result.data.pages,
        payload: result.data.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'LIST_VOUCHER_FAILURE',        
    error: error    
  })
}