import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'
import { encodeParams } from '../../helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListBanner, actionDeleteBanner } from '../../store/actions/banner'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const BannerList = ({ history, banner, deleteBanner, dispatchListBanner, dispatchDeleteBanner }) => {
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [banners, setBanners] = useState([])

  useEffect(() => {
    if(onState) {
      dispatchListBanner(page, limit)
    }
  }, [dispatchListBanner, onState, page, limit])

  useEffect(() => {
    if(banner.payload) {
      setBanners(banner.payload)
      setPage(banner.page)
      setPages(banner.pages)
      setOnState(false)
    }
  }, [banner.payload, banner.page, banner.pages])

  useEffect(() => {
    if(deleteBanner.success) {
      setOnState(true)
    }
  }, [deleteBanner.success])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleAddData = () => {
    history.push('/banner/create')
  }

  const handleUpdateData = (data) => {
    history.push(`/banner/update/${encodeParams(data)}`)
  }

  const handleDeleteData = (id) => {
    dispatchDeleteBanner(id)
    if(banners.length === 1) {
      setPage((page > 1)? page - 1 : 1)
    }
  }

  return (
    <div className={classes.root}>
      <ExToolbar onAddData={handleAddData} />
      <div className={classes.content}>
        <ExTable 
          banners={banners}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDeleteData={handleDeleteData}
          onUpdateData={handleUpdateData}
        />
      </div>
    </div>
  )
}

BannerList.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    banner: {
      loading: state.banner.listBanner.loading,
      page: state.banner.listBanner.page,
      pages: state.banner.listBanner.pages,
      payload: state.banner.listBanner.payload
    },
    deleteBanner: {
      success: state.banner.deleteBanner.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListBanner: (page, limit) => dispatch(actionListBanner(page, limit)),
    dispatchDeleteBanner: (id) => dispatch(actionDeleteBanner(id))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(BannerList)
