import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionSaveProducer } from '../../store/actions/producer'

const schema = {
  name: {
    presence: { 
      allowEmpty: false, 
      message: 'Nama harus diisi' 
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  }
}))

const ProducerCreate = ({ history, saveProducer, dispatchSaveProducer }) => {
  const classes = useStyles()
  const [onState, setOnState] = useState(false)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  useEffect(() => {
    if(saveProducer.success && onState) {
      setOnState(false)
      history.goBack()
    }
  }, [saveProducer.success, onState, history])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleProducerCreate = event => {
    event.preventDefault()
    dispatchSaveProducer(formState.values)
    setOnState(true)
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleProducerCreate}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Tambah Produsen
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Nama produsen harus unik untuk memudahkan pencarian
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('name')}
                  fullWidth
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  label="Nama Produsen"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.createButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Simpan
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ProducerCreate.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    saveProducer: {
      loading: state.producer.saveProducer.loading,
      success: state.producer.saveProducer.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchSaveProducer: (data) => dispatch(actionSaveProducer(data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProducerCreate)