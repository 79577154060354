import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { RouteLayout, ProtectedRouteLayout, NonRouteLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts'

import { connect } from 'react-redux'

import {
  Dashboard as DashboardView,
  Login as LoginView,
  NotFound as NotFoundView,
  AgentList as AgentListView,
  AgentCreate as AgentCreateView,
  AgentUpdate as AgentUpdateView,
  AgentListSGH as AgentListSGHView,
  AgentCreateSGH as AgentCreateSGHView,
  AgentUpdateSGH as AgentUpdateSGHView,
  AgentListDropshipping as AgentListDropshippingView,
  AgentCreateDropshipping as AgentCreateDropshippingView,
  AgentUpdateDropshipping as AgentUpdateDropshippingView,
  ProducerList as ProducerListView,
  ProducerCreate as ProducerCreateView,
  ProducerUpdate as ProducerUpdateView,
  CategoryList as CategoryListView,
  CategoryCreate as CategoryCreateView,
  CategoryUpdate as CategoryUpdateView,
  VoucherList as VoucherListView,
  VoucherListUsed as VoucherListUsedView,
  VoucherCreate as VoucherCreateView,
  BannerList as BannerListView,
  BannerCreate as BannerCreateView,
  BannerUpdate as BannerUpdateView,
  ProductList as ProductListView,
  ProductCreate as ProductCreateView,
  ProductUpdate as ProductUpdateView,
  TransactionList as TransactionListView,
  TransactionListSGH as TransactionListSGHView,
  TransactionListDropshipping as TransactionListDropshippingView
} from './views'

const Routes = ({ authenticate }) => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      {/* agent */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentListDropshippingView}
        exact
        layout={MainLayout}
        path="/agent-dropshipping"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentCreateDropshippingView}
        exact
        layout={MainLayout}
        path="/agent-dropshipping/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentUpdateDropshippingView}
        exact
        layout={MainLayout}
        path="/agent-dropshipping/update/:buffer"
      />
      {/* agent */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentListSGHView}
        exact
        layout={MainLayout}
        path="/agent-sgh"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentCreateSGHView}
        exact
        layout={MainLayout}
        path="/agent-sgh/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentUpdateSGHView}
        exact
        layout={MainLayout}
        path="/agent-sgh/update/:buffer"
      />
      {/* agent */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentListView}
        exact
        layout={MainLayout}
        path="/agent"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentCreateView}
        exact
        layout={MainLayout}
        path="/agent/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={AgentUpdateView}
        exact
        layout={MainLayout}
        path="/agent/update/:buffer"
      />
      {/* producer */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={ProducerListView}
        exact
        layout={MainLayout}
        path="/producer"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={ProducerCreateView}
        exact
        layout={MainLayout}
        path="/producer/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={ProducerUpdateView}
        exact
        layout={MainLayout}
        path="/producer/update/:buffer"
      />
      {/* category */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={CategoryListView}
        exact
        layout={MainLayout}
        path="/category"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={CategoryCreateView}
        exact
        layout={MainLayout}
        path="/category/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={CategoryUpdateView}
        exact
        layout={MainLayout}
        path="/category/update/:buffer"
      />
      {/* banner */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={BannerListView}
        exact
        layout={MainLayout}
        path="/banner"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={BannerCreateView}
        exact
        layout={MainLayout}
        path="/banner/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={BannerUpdateView}
        exact
        layout={MainLayout}
        path="/banner/update/:buffer"
      />
      {/* product */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/product"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={ProductCreateView}
        exact
        layout={MainLayout}
        path="/product/create"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={ProductUpdateView}
        exact
        layout={MainLayout}
        path="/product/update/:productId"
      />
      {/* transaction */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={TransactionListView}
        exact
        layout={MainLayout}
        path="/transaction"
      />
      {/* transaction SGH */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={TransactionListSGHView}
        exact
        layout={MainLayout}
        path="/transaction-sgh"
      />
      {/* transaction Dropshipping */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={TransactionListDropshippingView}
        exact
        layout={MainLayout}
        path="/transaction-dropshipping"
      />
      {/* voucher */}
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={VoucherListView}
        exact
        layout={MainLayout}
        path="/voucher"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={VoucherListUsedView}
        exact
        layout={MainLayout}
        path="/voucher-used/:code"
      />
      <ProtectedRouteLayout
        isAuthenticated={authenticate.status}
        component={VoucherCreateView}
        exact
        layout={MainLayout}
        path="/voucher/create"
      />
      <NonRouteLayout
        isAuthenticated={authenticate.status}
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      status: state.auth.authenticate.status
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
