import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { decodeParams, listStaticTypes } from '../../helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionUpdateAgent } from '../../store/actions/agentSgh'

const schema = {
  name: {
    presence: { 
      allowEmpty: false, 
      message: 'Nama harus diisi' 
    }
  },
  email: {
    presence: false,
    email: {
      message: 'Alamat email tidak valid'
    }
  },
  phone: {
    presence: { 
      allowEmpty: false, 
      message: 'Nomor telepon harus diisi' 
    },
    numericality: { 
      onlyInteger: true, 
      notValid: 'Nomor telepon harus berupa angka' 
    }
  },
  address: {
    presence: { 
      allowEmpty: false, 
      message: 'Alamat harus diisi' 
    }
  },
  type: {
    presence: { 
      allowEmpty: false, 
      message: 'Tipe harus diisi' 
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  updateButton: {
    margin: theme.spacing(2, 0)
  }
}))

const AgentUpdateSGH = ({ history, match, updateAgent, dispatchUpdateAgent }) => {
  const classes = useStyles()
  const [onState, setOnState] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      type: 'sgh'
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    async function setState() {
      try {
        const params = await decodeParams(match.params.buffer)
        setSelectedId(params.id)
        setFormState(formState => ({
          ...formState,
          values: {
            name: params.name,
            phone: params.phone,
            email: params.email,
            address: params.address,
            type: params.type
          }
        }))
      } catch(err) {
        history.push('/not-found')
      }
    }

    setState()
  }, [match.params.buffer, history])

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  useEffect(() => {
    if(updateAgent.success && onState) {
      setOnState(false)
      history.goBack()
    }
  }, [updateAgent.success, onState, history])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleAgentUpdateSGH = event => {
    event.preventDefault()
    dispatchUpdateAgent(selectedId, formState.values)
    setOnState(true)
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleAgentUpdateSGH}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Edit Agen
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Gunakan fitur reset untuk mengubah kata sandi agen
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('name')}
                  fullWidth
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  label="Nama Agen"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('phone')}
                  fullWidth
                  helperText={
                    hasError('phone') ? formState.errors.phone[0] : null
                  }
                  label="Nomor Telepon Agen"
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phone || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email Agen"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('address')}
                  fullWidth
                  helperText={
                    hasError('address') ? formState.errors.address[0] : null
                  }
                  label="Alamat Agen"
                  name="address"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.address || ''}
                  variant="outlined"
                  multiline={true}
                  rows={3}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('type')}
                  fullWidth
                  select
                  helperText={
                    hasError('type') ? formState.errors.type[0] : null
                  }
                  label="Tipe Agen"
                  name="type"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.type}
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {
                    listStaticTypes.agentSgh.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))
                  }
                </TextField>
                <Button
                  className={classes.updateButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Simpan
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

AgentUpdateSGH.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    updateAgent: {
      loading: state.agent.updateAgent.loading,
      success: state.agent.updateAgent.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchUpdateAgent: (id, data) => dispatch(actionUpdateAgent(id, data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AgentUpdateSGH)