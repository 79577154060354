import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  IconButton
} from '@material-ui/core'

import { ExModalDelete } from 'components'
import PasswordIcon from '@material-ui/icons/Lock'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ExModalSuccess from '../ExModalSuccess'

import { getInitials, getGrade } from 'helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  updateButton: {
    marginRight: theme.spacing(1)
  }
}))

const ExTable = ({
  className, 
  agents, 
  page, 
  pages, 
  limit, 
  onChangePage, 
  onChangeRowsPerPage, 
  onDeleteData, 
  onUpdateData, 
  onResetPasswordData,
  modalResetPassword,
  onCloseModalResetPassword,
  password,
  ...rest 
}) => {
  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const classes = useStyles()

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleOpenModal = (id) => {
    setSelectedId(id)
    setModal(true)
  }

  const handleDeleteModal = () => {
    onDeleteData(selectedId)
    setModal(false)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Telepon</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Alamat</TableCell>
                  <TableCell>Tipe</TableCell>
                  <TableCell>Tanggal</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agents.map((agent, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={agent.id}
                  >
                    <TableCell>{((page*limit)-limit) + (index+1)}</TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={agent.avatarUrl}
                        >
                          {getInitials(agent.name)}
                        </Avatar>
                        <Typography variant="body1">{agent.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{agent.phone}</TableCell>
                    <TableCell>{agent.email}</TableCell>
                    <TableCell>{agent.address}</TableCell>
                    <TableCell>{getGrade(agent.type)}</TableCell>
                    <TableCell>
                      {moment(agent.created_at).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className={classes.updateButton}
                        color="inherit"
                        onClick={() => onResetPasswordData(agent.id)}
                      >
                        <PasswordIcon />
                      </IconButton>
                      <IconButton
                        className={classes.updateButton}
                        color="inherit"
                        onClick={() => onUpdateData(agent)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => handleOpenModal(agent.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pages * limit}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page-1}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      
      <ExModalDelete 
        open={modal} 
        onClose={handleCloseModal} 
        onDelete={handleDeleteModal}
      />

      <ExModalSuccess 
        open={modalResetPassword} 
        onClose={onCloseModalResetPassword} 
        password={password}
      />
    </Card>
  )
}

ExTable.propTypes = {
  className: PropTypes.string,
  agents: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDeleteData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired,
  onResetPasswordData: PropTypes.func.isRequired,
  modalResetPassword: PropTypes.bool.isRequired,
  onCloseModalResetPassword: PropTypes.func.isRequired,
  password: PropTypes.string
}

export default ExTable
