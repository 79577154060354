import { combineReducers } from 'redux'

import listBanner from './listBanner'
import saveBanner from './saveBanner'
import updateBanner from './updateBanner'
import deleteBanner from './deleteBanner'

export default combineReducers({
  listBanner: listBanner,
  saveBanner: saveBanner,
  updateBanner: updateBanner,
  deleteBanner: deleteBanner
})
