import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'

import { 
  decodeParams, 
  decodeFile, 
  listStaticTypes } from '../../helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionUpdateBanner } from '../../store/actions/banner'

const schema = {}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  textFile: {
    display: 'none'
  },
  iconFile: {
    cursor: 'pointer'
  },
  updateButton: {
    margin: theme.spacing(2, 0)
  },
  imageContainer: {
    marginTop: theme.spacing(2),
    height: '100%',
    width: '60%',
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  }
}))

const BannerUpdate = ({ history, match, updateBanner, dispatchUpdateBanner }) => {
  const classes = useStyles()
  const [onState, setOnState] = useState(false)
  const [selectedId, setSelectedId] = useState(0)
  const fileRef = useRef(null)
  const [base64, setBase64] = useState('')

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      type: 'herbalkatalog'
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    async function setState() {
      try {
        const params = await decodeParams(match.params.buffer)
        setSelectedId(params.id)
        setFormState(formState => ({
          ...formState,
          values: {
            url: params.url,
            type: params.type
          }
        }))
        setBase64(process.env.REACT_APP_IMAGE_URL + params.image)
      } catch(err) {
        history.push('/not-found')
      }
    }

    setState()
  }, [match.params.buffer, history])

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  useEffect(() => {
    if(updateBanner.success && onState) {
      setOnState(false)
      history.goBack()
    }
  }, [updateBanner.success, onState, history])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleChangeFile = async (event) => {
    event.persist()
    const file = await decodeFile(event.target.files[0])
    
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        image: file.decode,
        filename: file.name
      },
      touched: {
        ...formState.touched,
        image: file.decode,
        filename: file.name
      }
    }))
    setBase64(file.base64)
  }

  const handleBack = () => {
    history.goBack()
  }

  const handlePickFile = event => {
    fileRef.current.click()
  }

  const handleBannerUpdate = event => {
    event.preventDefault()
    dispatchUpdateBanner(selectedId, formState.values)
    setOnState(true)
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleBannerUpdate}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Edit Banner
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Tautan banner boleh dikosongkan
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('url')}
                  fullWidth
                  helperText={
                    hasError('url') ? formState.errors.url[0] : null
                  }
                  label="Tautan Banner"
                  name="url"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.url || ''}
                  variant="outlined"
                />
                
                <TextField
                  className={classes.textField}
                  error={hasError('type')}
                  fullWidth
                  select
                  helperText={
                    hasError('type') ? formState.errors.type[0] : null
                  }
                  label="Tipe Banner"
                  name="type"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.type}
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {
                    listStaticTypes.banner.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))
                  }
                </TextField>
                <input 
                  accept="image/*" 
                  className={classes.textFile}
                  type="file"
                  ref={fileRef}
                  name="image"
                  onChange={handleChangeFile}
                />  
                <TextField
                  className={classes.textField}
                  error={hasError('filename')}
                  fullWidth
                  helperText={
                    hasError('filename') ? formState.errors.filename[0] : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment 
                        className={classes.iconFile} 
                        position="start"
                      >
                        <AddPhotoAlternateIcon />
                      </InputAdornment>
                    ),
                  }}
                  label="Gambar Banner"
                  placeholder="Ubah Gambar"
                  name="filename"
                  type="text"
                  onClick={handlePickFile}
                  value={formState.values.filename || ''}
                  variant="outlined"
                  disabled
                />
                {
                  (base64)
                  ? <div className={classes.imageContainer}>
                      <img 
                        className={classes.image}
                        alt="Gambar Banner"
                        src={base64}
                      />
                    </div>
                  : null
                }
                <Button
                  className={classes.updateButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Simpan
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

BannerUpdate.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    updateBanner: {
      loading: state.banner.updateBanner.loading,
      success: state.banner.updateBanner.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchUpdateBanner: (id, data) => dispatch(actionUpdateBanner(id, data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(BannerUpdate)