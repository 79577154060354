import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}))

const TotalUsers = props => {
  const { className, countAll, count, countSgh,countDropshipping, ...rest } = props

  const classes = useStyles()

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL AGEN
            </Typography>
            <Typography 
              variant="h3" 
              gutterBottom
            >
              {countAll}
            </Typography>
            <Typography variant="h5">Herbal Katalog : {count}</Typography>
            <Typography variant="h5">Super Grosir Herbal : {countSgh}</Typography>
            <Typography variant="h5">Dropshipping Herbal : {countDropshipping}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {/* <div className={classes.difference}>
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div> */}
      </CardContent>
    </Card>
  )
}

TotalUsers.propTypes = {
  className: PropTypes.string,
  countAll: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  countSgh: PropTypes.number.isRequired,
  countDropshipping: PropTypes.number.isRequired
}

export default TotalUsers
