import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { 
  actionListProduct, 
  actionDeleteProduct
} from '../../store/actions/product'
import { actionListProducer } from '../../store/actions/producer'
import { actionListCategory } from '../../store/actions/category'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const ProductList = ({ 
  history, 
  product, 
  producer, 
  category,
  deleteProduct, 
  dispatchListProduct, 
  dispatchDeleteProduct, 
  dispatchListCategory, 
  dispatchListProducer
}) => {
  var delay = null
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [name, setName] = useState('')
  const [producerId, setProducerId] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [producers, setProducers] = useState([])

  useEffect(() => {
    if(onState) {
      dispatchListProduct(page, limit, name, categoryId, producerId)
    }
  }, [dispatchListProduct, onState, page, limit, name, categoryId, producerId])

  useEffect(() => {
    dispatchListProducer(1, 1000, '')
    dispatchListCategory(1, 1000, '')
  }, [dispatchListProducer, dispatchListCategory])

  useEffect(() => {
    if(category.payload.length > 0)
    category.payload.unshift({ id: '', name: 'Semua Kategori' })
    setCategories(category.payload)
  }, [category.payload])

  useEffect(() => {
    if(producer.payload.length > 0)
    producer.payload.unshift({ id: '', name: 'Semua Produsen' })
    setProducers(producer.payload)
  }, [producer.payload])

  useEffect(() => {
    if(product.payload) {
      setProducts(product.payload)
      setPage(product.page)
      setPages(product.pages)
      setOnState(false)
    }
  }, [product.payload, product.page, product.pages])

  useEffect(() => {
    if(deleteProduct.success) {
      setOnState(true)
    }
  }, [deleteProduct.success])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!product.loading) {
      delay = setTimeout(() => {
        setName(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  const handleAddData = () => {
    history.push('/product/create')
  }

  const handleUpdateData = (productId) => {
    history.push(`/product/update/${productId}`)
  }

  const handleDeleteData = (id) => {
    dispatchDeleteProduct(id)
    if(products.length === 1) {
      setPage((page > 1)? page - 1 : 1)
    }
  }

  const handleChangeProducerId = (event) => {
    event.persist()
    setProducerId(event.target.value)
    setOnState(true)
  }

  const handleChangeCategoryId = (event) => {
    event.persist()
    setCategoryId(event.target.value)
    setOnState(true)
  }

  return (
    <div className={classes.root}>
      <ExToolbar 
        onSearch={handleSearch} 
        onAddData={handleAddData} 
        producers={producers}
        categories={categories}
        producerId={producerId}
        categoryId={categoryId}
        onChangeProducerId={handleChangeProducerId}
        onChangeCategoryId={handleChangeCategoryId}
      />

      <div className={classes.content}>
        <ExTable 
          products={products}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDeleteData={handleDeleteData}
          onUpdateData={handleUpdateData}
        />
      </div>
    </div>
  )
}

ProductList.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    product: {
      loading: state.product.listProduct.loading,
      page: state.product.listProduct.page,
      pages: state.product.listProduct.pages,
      payload: state.product.listProduct.payload
    },
    deleteProduct: {
      success: state.product.deleteProduct.success
    },
    producer: {
      loading: state.producer.listProducer.loading,
      payload: state.producer.listProducer.payload
    },
    category: {
      loading: state.category.listCategory.loading,
      payload: state.category.listCategory.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListProduct: (page, limit, name, categoryId, producerId) => dispatch(actionListProduct(page, limit, name, categoryId, producerId)),
    dispatchDeleteProduct: (id) => dispatch(actionDeleteProduct(id)),
    dispatchListCategory: (page, limit, name) => dispatch(actionListCategory(page, limit, name)),
    dispatchListProducer: (page, limit, name) => dispatch(actionListProducer(page, limit, name))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductList)
