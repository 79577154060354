import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const NonRouteLayout = props => {
  const { isAuthenticated, layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if(isAuthenticated) {
          return (
            <Redirect to="/dashboard" />
          )
        }

        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )
      }}
    />
  );
};

NonRouteLayout.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default NonRouteLayout
