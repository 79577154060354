import { combineReducers } from 'redux'

import listProduct from './listProduct'
import saveProduct from './saveProduct'
import updateProduct from './updateProduct'
import deleteProduct from './deleteProduct'
import detailProduct from './detailProduct'

export default combineReducers({
  listProduct: listProduct,
  saveProduct: saveProduct,
  updateProduct: updateProduct,
  deleteProduct: deleteProduct,
  detailProduct: detailProduct
})
