const initialState = { 
  loading: false,
  payload: [],
  page: 1,
  pages: 1,
  sum: 0,
  error: null
}

function listTransactionReducer(state = initialState, action) {
  switch (action.type) {
    case 'LIST_TRANSACTION_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'LIST_TRANSACTION_SUCCESS':
      return {
        ...state,
        loading: false,
        page: action.page,
        pages: action.pages,
        sum: action.sum,
        payload: action.payload
      }

    case 'LIST_TRANSACTION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default listTransactionReducer