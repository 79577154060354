import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import PropTypes from 'prop-types'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ExAlert = props => {
  const { open, severity, message, onClose  } = props

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  )
}

ExAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

export default ExAlert