import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton
} from '@material-ui/core'

import { numberFormat, dateFormat, getGradeDropshipping, numberFormatTotal } from 'helpers'
import { ExModalDetail, ExModalFinish } from '../ExModal'

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import CheckIcon from '@material-ui/icons/Check'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  updateButton: {
    marginRight: theme.spacing(1)
  },
  imageContainer: {
    height: 96,
    width: 96,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  discountContainer: {
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    display: 'flex',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1
  }
}))

const ExTable = props => {
  const { 
    className, 
    transactions, 
    page, 
    pages, 
    limit, 
    onChangePage, 
    onChangeRowsPerPage,
    onFinishData,
    ...rest 
  } = props
  const classes = useStyles()

  const [selectedId, setSelectedId] = useState(0)
  const [modalDetail, setModalDetail] = useState(false)
  const [modalFinish, setModalFinish] = useState(false)
  const [detailData, setDetailData] = useState(null)

  const handleShowDetail = (item) => {
    setDetailData(item)
    setModalDetail(true)
  }

  const handleCloseDetail = () => {
    setModalDetail(false)
  }

  const handleShowFinish = (transactionId) => {
    setSelectedId(transactionId)
    setModalFinish(true)
  }

  const handleCloseFinish = () => {
    setModalFinish(false)
  }

  const handleOnFinish = () => {
    onFinishData(selectedId)
    setModalFinish(false)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Kode</TableCell>
                  <TableCell>Tipe Agen</TableCell>
                  <TableCell>Agen</TableCell>
                  <TableCell>Jalur</TableCell>
                  <TableCell>Total Belanja</TableCell>
                  <TableCell>Total Diskon</TableCell>
                  <TableCell>Total Bayar</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Tanggal</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={transaction.id}
                  >
                    <TableCell>{((page*limit)-limit) + (index+1)}</TableCell>
                    <TableCell>{transaction.code}</TableCell>
                    <TableCell>
                      {
                        (transaction.user)
                        ? getGradeDropshipping(transaction.user.type)
                        : '-'
                      }
                    </TableCell>
                    <TableCell>
                      {
                        (transaction.user)
                        ? `${transaction.user.name} - ${transaction.user.phone}`
                        : '-'
                      }
                    </TableCell>
                    <TableCell>{transaction.main}</TableCell>
                    <TableCell>{numberFormat(transaction.total, 'Rp. ')}</TableCell>
                    <TableCell>
                      {
                        (transaction.voucher)
                          ? numberFormat(transaction.voucher?.value, 'Rp. ')
                          : '-'
                      }
                    </TableCell>
                    <TableCell>{numberFormatTotal(transaction.total, transaction.voucher, 'Rp. ')}</TableCell>
                    <TableCell>{(transaction.status === 0)? 'Dalam Proses' : 'Selesai'}</TableCell>
                    <TableCell>{dateFormat(transaction.created_at)}</TableCell>
                    <TableCell>
                      {
                        (transaction.status === 0)
                        ? <IconButton
                            className={classes.updateButton}
                            color="primary"
                            onClick={() => handleShowFinish(transaction.id)}
                          >
                            <CheckIcon />
                          </IconButton>
                        : <IconButton
                            className={classes.updateButton}
                            color="inherit"
                          >
                            <PlaylistAddCheckIcon />
                          </IconButton>
                      }
                      <IconButton
                        color="inherit"
                        onClick={() => handleShowDetail(transaction)}
                      >
                        <MenuOpenIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pages * limit}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page-1}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>

      {
        (detailData)
        ? <ExModalDetail 
            open={modalDetail} 
            onClose={handleCloseDetail} 
            transaction={detailData}
          />
        : null
      }
      
      <ExModalFinish 
        open={modalFinish} 
        onClose={handleCloseFinish} 
        onFinish={handleOnFinish}
      />
    </Card>
  )
}

ExTable.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onFinishData: PropTypes.func.isRequired
}

export default ExTable
