import React, { useState } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Chart } from 'react-chartjs-2'
import { ThemeProvider } from '@material-ui/styles'
import validate from 'validate.js'

// redux
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import ReduxThunk from 'redux-thunk'

import { chartjs } from './helpers'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import validators from './common/validators'
import Routes from './Routes'

import { actionAlert } from './store/actions/util'
import { Splash as SplashView } from './views'

import authReducer from './store/reducers/auth'
import utilReducer from './store/reducers/util'
import agentReducer from './store/reducers/agent'
import agentSghReducer from './store/reducers/agentSgh'
import agentDropshippingReducer from './store/reducers/agentDropshipping'
import categoryReducer from './store/reducers/category'
import producerReducer from './store/reducers/producer'
import bannerReducer from './store/reducers/banner'
import productReducer from './store/reducers/product'
import transactionReducer from './store/reducers/transaction'
import transactionSghReducer from './store/reducers/transactionSgh'
import transactionDropshippingReducer from './store/reducers/transactionDropshipping'
import dashboardReducer from './store/reducers/dashboard'
import voucherReducer from './store/reducers/voucher'

const rootReducer = combineReducers({
  auth: authReducer,
  util: utilReducer,
  agent: agentReducer,
  agentSgh: agentSghReducer,
  agentDropshipping: agentDropshippingReducer,
  category: categoryReducer,
  producer: producerReducer,
  product: productReducer,
  banner: bannerReducer,
  transaction: transactionReducer,
  transactionSgh: transactionSghReducer,
  transactionDropshipping: transactionDropshippingReducer,
  dashboard: dashboardReducer,
  voucher: voucherReducer,
})

function logger({ dispatch }) {
  return next => action => {
    if(action.error) {
      dispatch(actionAlert(true, 'error', action.error.message))
    }

    if(action.success) {
      dispatch(actionAlert(true, 'success', action.success.message))
    }

    return next(action)
  }
}

const store = createStore(rootReducer, applyMiddleware(ReduxThunk, logger))

const browserHistory = createBrowserHistory()

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
})

validate.validators = {
  ...validate.validators,
  ...validators
}

const App = () => {
  const [loading, setLoading] = useState(true)
  
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {
          (loading)
          ? <SplashView handleEndedSplash={() => setLoading(false)} />
          : <Router history={browserHistory}>
              <Routes />
            </Router>
        }
      </ThemeProvider>
    </Provider>
  )
}

export default App