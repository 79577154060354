import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'
import { encodeParams } from '../../helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListCategory, actionDeleteCategory } from '../../store/actions/category'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const CategoryList = ({ history, category, deleteCategory, dispatchListCategory, dispatchDeleteCategory }) => {
  var delay = null
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [name, setName] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    if(onState) {
      dispatchListCategory(page, limit, name)
    }
  }, [dispatchListCategory, onState, page, limit, name])

  useEffect(() => {
    if(category.payload) {
      setCategories(category.payload)
      setPage(category.page)
      setPages(category.pages)
      setOnState(false)
    }
  }, [category.payload, category.page, category.pages])

  useEffect(() => {
    if(deleteCategory.success) {
      setOnState(true)
    }
  }, [deleteCategory.success])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!category.loading) {
      delay = setTimeout(() => {
        setName(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  const handleAddData = () => {
    history.push('/category/create')
  }

  const handleUpdateData = (data) => {
    history.push(`/category/update/${encodeParams(data)}`)
  }

  const handleDeleteData = (id) => {
    dispatchDeleteCategory(id)
    if(categories.length === 1) {
      setPage((page > 1)? page - 1 : 1)
    }
  }

  return (
    <div className={classes.root}>
      <ExToolbar onSearch={handleSearch} onAddData={handleAddData} />
      <div className={classes.content}>
        <ExTable 
          categories={categories}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDeleteData={handleDeleteData}
          onUpdateData={handleUpdateData}
        />
      </div>
    </div>
  )
}

CategoryList.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    category: {
      loading: state.category.listCategory.loading,
      page: state.category.listCategory.page,
      pages: state.category.listCategory.pages,
      payload: state.category.listCategory.payload
    },
    deleteCategory: {
      success: state.category.deleteCategory.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListCategory: (page, limit, name) => dispatch(actionListCategory(page, limit, name)),
    dispatchDeleteCategory: (id) => dispatch(actionDeleteCategory(id))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CategoryList)
