import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListVoucher, actionDeleteVoucher } from '../../store/actions/voucher'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const VoucherList = ({ history, voucher, deleteVoucher, dispatchListVoucher, dispatchDeleteVoucher }) => {
  var delay = null
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [name, setName] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [vouchers, setVouchers] = useState([])

  useEffect(() => {
    if(onState) {
      dispatchListVoucher(page, limit, name)
    }
  }, [dispatchListVoucher, onState, page, limit, name])

  useEffect(() => {
    if(voucher.payload) {
      setVouchers(voucher.payload)
      setPage(voucher.page)
      setPages(voucher.pages)
      setOnState(false)
    }
  }, [voucher.payload, voucher.page, voucher.pages])

  useEffect(() => {
    if(deleteVoucher.success) {
      setOnState(true)
    }
  }, [deleteVoucher.success])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!voucher.loading) {
      delay = setTimeout(() => {
        setName(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  const handleAddData = () => {
    history.push('/voucher/create')
  }

  const handleDetailData = (code) => {
    history.push(`/voucher-used/${code}`)
  }

  const handleDeleteData = (id) => {
    dispatchDeleteVoucher(id)
    if(vouchers.length === 1) {
      setPage((page > 1)? page - 1 : 1)
    }
  }

  return (
    <div className={classes.root}>
      <ExToolbar onSearch={handleSearch} onAddData={handleAddData} />
      <div className={classes.content}>
        <ExTable 
          items={vouchers}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDeleteData={handleDeleteData}
          onDetailData={handleDetailData}
        />
      </div>
    </div>
  )
}

VoucherList.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    voucher: {
      loading: state.voucher.listVoucher.loading,
      page: state.voucher.listVoucher.page,
      pages: state.voucher.listVoucher.pages,
      payload: state.voucher.listVoucher.payload
    },
    deleteVoucher: {
      success: state.voucher.deleteVoucher.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListVoucher: (page, limit, name) => dispatch(actionListVoucher(page, limit, name)),
    dispatchDeleteVoucher: (id) => dispatch(actionDeleteVoucher(id))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(VoucherList)
