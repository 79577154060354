import { combineReducers } from 'redux'

import listVoucher from './listVoucher'
import listVoucherUsed from './listVoucherUsed'
import saveVoucher from './saveVoucher'
import updateVoucher from './updateVoucher'
import deleteVoucher from './deleteVoucher'

export default combineReducers({
  listVoucher,
  listVoucherUsed,
  saveVoucher,
  updateVoucher,
  deleteVoucher
})
