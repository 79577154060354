

export default (file = null) => {
  return new Promise((resolve, _) => {
    if (file) {
      const fr = new FileReader()
      fr.readAsDataURL(file)
      fr.addEventListener('load', () => {
        resolve({
          name: file.name,
          decode: fr.result.split(',')[1],
          base64: fr.result
        })
      })
    } else {
      resolve({ 
        name: '', 
        decode: '',
        base64: ''
      })
    }
  })
}
  