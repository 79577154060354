import { queryFormat } from '../../../helpers'

function requestAPI(queryString, token) {
  return fetch(`${process.env.REACT_APP_ADMIN_URL}transaction?${queryString}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((res) => res.json())
}

export default function actionListTransaction(page, limit, code, startDate, endDate) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'LIST_TRANSACTION_BEGIN'
    })

    try {
      const queryString = await queryFormat({ 
        page: page, 
        limit: limit, 
        code: code, 
        start_date: (startDate)? startDate : '',
        end_date: (endDate)? endDate : ''
      })
      const token = await getState().auth.authenticate.token
      const result = await requestAPI(queryString, token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'LIST_TRANSACTION_SUCCESS',
        page: result.data.page,
        pages: result.data.pages,
        sum: result.data.sum,
        payload: result.data.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'LIST_TRANSACTION_FAILURE',        
    error: error    
  })
}