const initialState = { 
  loading: false,
  success: null,
  error: null
}

function updateBannerReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_BANNER_BEGIN':
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }

    case 'UPDATE_BANNER_SUCCESS':
      return {
        ...state,
        loading: false,
        success: action.success
      }

    case 'UPDATE_BANNER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default updateBannerReducer