import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionSaveVoucher } from '../../store/actions/voucher'

import { listStaticTypes } from '../../helpers'
import { ExNumberFormat } from '../../components'

const schema = {
  group: {
    presence: { 
      allowEmpty: false, 
      message: 'Tipe harus diisi' 
    }
  },
  code: {
    presence: { 
      allowEmpty: false, 
      message: 'Kode harus diisi' 
    }
  },
  value: {
    presence: { 
      allowEmpty: false, 
      message: 'Nilai harus diisi' 
    }
  },
  start: {
    presence: { 
      allowEmpty: false, 
      message: 'Tanggal Mulai harus diisi' 
    }
  },
  end: {
    presence: { 
      allowEmpty: false, 
      message: 'Tanggal Berakhir harus diisi' 
    }
  },
  max_use: {
    presence: { 
      allowEmpty: false, 
      message: 'Maksimal Digunakan harus diisi' 
    }
  },
  min_purchase: {
    presence: { 
      allowEmpty: false, 
      message: 'Mimimal Pembelian harus diisi' 
    }
  },
  quantity: {
    presence: { 
      allowEmpty: false, 
      message: 'Jumlah harus diisi' 
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(2, 0)
  }
}))

const VoucherCreate = ({ history, saveVoucher, dispatchSaveVoucher }) => {
  const classes = useStyles()
  const [onState, setOnState] = useState(false)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      group: listStaticTypes.banner[0].id
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  useEffect(() => {
    if(saveVoucher.success && onState) {
      setOnState(false)
      history.goBack()
    }
  }, [saveVoucher.success, onState, history])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleChangeNumber = event => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleVoucherCreate = event => {
    event.preventDefault()
    dispatchSaveVoucher(formState.values)
    setOnState(true)
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleVoucherCreate}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Tambah Voucher
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Kode Voucher harus unik untuk memudahkan pencarian
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('code')}
                  fullWidth
                  helperText={
                    hasError('code') ? formState.errors.code[0] : null
                  }
                  label="Kode Voucher"
                  name="code"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.code || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('group')}
                  fullWidth
                  select
                  helperText={
                    hasError('group') ? formState.errors.group[0] : null
                  }
                  label="Tipe Voucher"
                  name="group"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.group}
                  SelectProps={{ native: true }}
                  variant="outlined"
                >
                  {
                    listStaticTypes.banner.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))
                  }
                </TextField>
                <TextField
                  className={classes.textField}
                  error={hasError('value')}
                  fullWidth
                  helperText={
                    hasError('value') ? formState.errors.value[0] : null
                  }
                  label="Nilai Voucher"
                  name="value"
                  onChange={handleChangeNumber}
                  value={formState.values.value || ''}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    startAdornment: (
                      <InputAdornment position="start">Rp. </InputAdornment>
                    )
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('start')}
                  fullWidth
                  helperText={
                    hasError('start') ? formState.errors.start[0] : null
                  }
                  label="Tanggal Mulai Voucher"
                  name="start"
                  onChange={handleChange}
                  type="date"
                  value={formState.values.start || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('end')}
                  fullWidth
                  helperText={
                    hasError('end') ? formState.errors.end[0] : null
                  }
                  label="Tanggal Berakhir Voucher"
                  name="end"
                  onChange={handleChange}
                  type="date"
                  value={formState.values.end || ''}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('max_use')}
                  fullWidth
                  helperText={
                    hasError('max_use') ? formState.errors.max_use[0] : null
                  }
                  label="Maksimal Digunakan Voucher / Agen"
                  name="max_use"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat
                  }}
                  type="text"
                  value={formState.values.max_use || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('min_purchase')}
                  fullWidth
                  helperText={
                    hasError('min_purchase') ? formState.errors.min_purchase[0] : null
                  }
                  label="Minimal Pembelian"
                  name="min_purchase"
                  onChange={handleChangeNumber}
                  value={formState.values.min_purchase || ''}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    startAdornment: (
                      <InputAdornment position="start">Rp. </InputAdornment>
                    )
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('quantity')}
                  fullWidth
                  helperText={
                    hasError('quantity') ? formState.errors.quantity[0] : null
                  }
                  label="Stok Voucher"
                  name="quantity"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat
                  }}
                  type="text"
                  value={formState.values.quantity || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.createButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Simpan
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

VoucherCreate.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    saveVoucher: {
      loading: state.voucher.saveVoucher.loading,
      success: state.voucher.saveVoucher.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchSaveVoucher: (data) => dispatch(actionSaveVoucher(data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(VoucherCreate)