import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}))

const TotalProfit = props => {
  const { className, countAll, count, countSgh, countDropshipping, ...rest } = props

  const classes = useStyles()

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              TOTAL TRANSAKSI
            </Typography>
            <Typography
              color="inherit"
              variant="h3"
              gutterBottom
            >
              {countAll}
            </Typography>
            <Typography 
              color="inherit"
              variant="h5"
            >
              Herbal Katalog : {count}
            </Typography>
            <Typography 
              color="inherit"
              variant="h5"
            >
                Super Grosir Herbal : {countSgh}
            </Typography>
            <Typography 
              color="inherit"
              variant="h5"
            >
                Dropshipping Herbal : {countDropshipping}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <ShoppingCartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

TotalProfit.propTypes = {
  className: PropTypes.string,
  countAll: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  countSgh: PropTypes.number.isRequired
}

export default TotalProfit
