import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit
} from './components'

import { connect } from 'react-redux'
import { actionGetDashboard } from '../../store/actions/dashboard'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const Dashboard = ({ dashboard, dispatchGetDashboard }) => {
  const classes = useStyles()
  const [countProducer, setCountProducer] = useState(0)
  const [countAgent, setCountAgent] = useState(0)
  const [countAgentSgh, setCountAgentSgh] = useState(0)
  const [countAgentDropshipping, setCountAgentDropshipping] = useState(0)
  const [countProduct, setCountProduct] = useState(0)
  const [countTransaction, setCountTransaction] = useState(0)
  const [countTransactionSgh, setCountTransactionSgh] = useState(0)
  const [countTransactionDropshipping, setCountTransactionDropshipping] = useState(0)

  useEffect(() => {
    dispatchGetDashboard()
  }, [dispatchGetDashboard])

  useEffect(() => {
    if(dashboard.payload) {
      setCountProducer(dashboard.payload.producer)
      setCountAgent(dashboard.payload.agent)
      setCountAgentSgh(dashboard.payload.agent_sgh)
      setCountAgentDropshipping(dashboard.payload.agent_dropshipping)
      setCountProduct(dashboard.payload.product)
      setCountTransaction(dashboard.payload.transaction)
      setCountTransactionSgh(dashboard.payload.transaction_sgh)
      setCountTransactionDropshipping(dashboard.payload.transaction_dropshipping)
    }
  }, [dashboard.payload])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Budget count={countProducer} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TasksProgress count={countProduct} />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <TotalUsers 
            countAll={(countAgent + countAgentSgh + countAgentDropshipping)} 
            count={countAgent} 
            countSgh={countAgentSgh} 
            countDropshipping={countAgentDropshipping} 
          />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          {/* <TotalProfit count={countTransaction} /> */}
          <TotalProfit 
            countAll={(countTransaction + countTransactionSgh + countTransactionDropshipping)} 
            count={countTransaction} 
            countSgh={countTransactionSgh} 
            countDropshipping={countTransactionDropshipping} 
          />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dashboard: {
      payload: state.dashboard.getDashboard.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchGetDashboard: () => dispatch(actionGetDashboard())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
