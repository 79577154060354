import { combineReducers } from 'redux'

import listProducer from './listProducer'
import saveProducer from './saveProducer'
import updateProducer from './updateProducer'
import deleteProducer from './deleteProducer'

export default combineReducers({
  listProducer: listProducer,
  saveProducer: saveProducer,
  updateProducer: updateProducer,
  deleteProducer: deleteProducer
})
