const initialState = { 
  loading: false,
  payload: [],
  page: 1,
  pages: 1,
  error: null
}

function listVoucherReducer(state = initialState, action) {
  switch (action.type) {
    case 'LIST_VOUCHER_USED_BEGIN':
      return {
        ...state,
        loading: true,
        error: null
      }

    case 'LIST_VOUCHER_USED_SUCCESS':
      return {
        ...state,
        loading: false,
        page: action.page,
        pages: action.pages,
        payload: action.payload
      }

    case 'LIST_VOUCHER_USED_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default listVoucherReducer
