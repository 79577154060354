import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

// import { Topbar } from './components'
import { ExAlert } from '../../components'

import { connect } from 'react-redux'
import { actionAlert } from '../../store/actions/util'

const useStyles = makeStyles(() => ({
  root: {
    // paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
}))

const Minimal = ({ children, alert, dispatchAlert }) => {
  const classes = useStyles()

  const handleClose = () => {
    dispatchAlert(false, alert.severity, alert.message)
  }

  return (
    <div className={classes.root}>
      {/* <Topbar /> */}
      <main className={classes.content}>{children}</main>

      <ExAlert 
        open={alert.status} 
        onClose={handleClose} 
        severity={alert.severity} 
        message={alert.message}  
      />
    </div>
  )
}

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    alert: {
      status: state.util.alert.status,
      severity: state.util.alert.severity,
      message: state.util.alert.message
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchAlert: (status, message) => dispatch(actionAlert(status, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Minimal)
