import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Chip,
  Typography
} from '@material-ui/core'

import { numberFormat } from 'helpers'
import { ExModalDelete } from 'components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  updateButton: {
    marginRight: theme.spacing(1)
  },
  imageContainer: {
    height: 96,
    width: 96,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  discountContainer: {
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const ExTable = props => {
  const { 
    className, 
    products, 
    page, 
    pages, 
    limit, 
    onChangePage, 
    onChangeRowsPerPage, 
    onDeleteData, 
    onUpdateData, 
    ...rest 
  } = props

  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const classes = useStyles()

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleOpenModal = (id) => {
    setSelectedId(id)
    setModal(true)
  }

  const handleDeleteModal = () => {
    onDeleteData(selectedId)
    setModal(false)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Gambar</TableCell>
                  <TableCell>Berat</TableCell>
                  <TableCell>Jumlah</TableCell>
                  <TableCell>Harga</TableCell>
                  <TableCell>Kategori</TableCell>
                  <TableCell>Produsen</TableCell>
                  <TableCell>Diskon</TableCell>
                  <TableCell>Deskripsi</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={product.id}
                  >
                    <TableCell>{((page*limit)-limit) + (index+1)}</TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      <div className={classes.imageContainer}>
                        <img
                          key={product.image}
                          alt={product.name}
                          className={classes.image}
                          src={process.env.REACT_APP_IMAGE_URL + product.image}
                        />
                      </div>
                    </TableCell>
                    <TableCell>{product.weight}</TableCell>
                    <TableCell>{product.amount+" /Karton"}</TableCell>
                    <TableCell>{numberFormat(product.price, 'Rp. ')}</TableCell>
                    <TableCell>{product.category?.name}</TableCell>
                    <TableCell>{product.producer?.name}</TableCell>
                    <TableCell>  
                      <div className={classes.discountContainer}>
                        <Chip
                          label="SGH"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography>{numberFormat(product.discount?.sgh, '')}%</Typography>
                          </div>
                          }
                        />
                        <Chip
                          label="A+"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography>{numberFormat(product.discount?.grade_aplus, '')}%</Typography>
                          </div>
                          }
                        />
                        <Chip
                          label="A"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography>{numberFormat(product.discount?.grade_a, '')}%</Typography>
                          </div>
                          }
                        />
                        <Chip
                          label="B+"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography>{numberFormat(product.discount?.grade_bplus, '')}%</Typography>
                          </div>
                          }
                        />
                      </div>
                      <div className={classes.discountContainer}>
                        <Chip
                          label="B"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography>{numberFormat(product.discount?.grade_b, '')}%</Typography>
                          </div>
                          }
                        />
                        <Chip
                          label="C"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography>{numberFormat(product.discount?.grade_c, '')}%</Typography>
                          </div>
                          }
                        />
                        <Chip
                          label="Eceran"
                          icon={
                            <div className={classes.chip}>
                              <DonutLargeIcon />
                              <Typography >{numberFormat(product.discount?.retail, '')}%</Typography>
                          </div>
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography  
                        style={
                          {
                            display: "-webkit-box",
                            webkitLineClamp: "3",
                            webkitBoxOrient: "vertical",
                            overflow: "hidden", 
                            textOverflow: "ellipsis", 
                          }} >
                        {product.description}
                      </Typography  >
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className={classes.updateButton}
                        color="inherit"
                        onClick={() => onUpdateData(product.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => handleOpenModal(product.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pages * limit}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page-1}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      
      <ExModalDelete 
        open={modal} 
        onClose={handleCloseModal} 
        onDelete={handleDeleteModal}
      />
    </Card>
  )
}

ExTable.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDeleteData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired
}

export default ExTable
