
function requestAPI(id, token) {
  return fetch(`${process.env.REACT_APP_ADMIN_URL}agent/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((res) => res.json())
}

export default function actionDeleteAgent(id) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'DELETE_AGENT_BEGIN'
    })

    try {
      const token = await getState().auth.authenticate.token
      const result = await requestAPI(id, token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'DELETE_AGENT_SUCCESS',
        success: result
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'DELETE_AGENT_FAILURE',        
    error: error    
  })
}