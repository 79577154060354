import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'

import { Sidebar, Topbar, Footer } from './components'
import { ExAlert } from '../../components'

import { connect } from 'react-redux'
import { actionAlert } from '../../store/actions/util'
import { actionLogout } from '../../store/actions/auth'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}))

const Main = ({ children, alert, account, dispatchAlert, dispatchLogout }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  const handleClose = () => {
    dispatchAlert(false, alert.severity, alert.message)
  }

  const handleLogout = () => {
    dispatchLogout()
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} onLogout={handleLogout} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        account={account}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>

      <ExAlert 
        open={alert.status} 
        onClose={handleClose} 
        severity={alert.severity} 
        message={alert.message}  
      />
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node
}

const mapStateToProps = (state) => {
  return {
    account: state.auth.authenticate.account,
    alert: {
      status: state.util.alert.status,
      severity: state.util.alert.severity,
      message: state.util.alert.message
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchAlert: (status, message) => dispatch(actionAlert(status, message)),
    dispatchLogout: () => dispatch(actionLogout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
