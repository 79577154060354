import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button, TextField } from '@material-ui/core'

import { SearchInput } from 'components'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  selectProducer: {
    marginLeft: theme.spacing(2),
    flexBasis: 220,
    fontSize: 36
  },
  selectCategory: {
    marginLeft: theme.spacing(2),
    flexBasis: 220,
    fontSize: 36
  }
}))

const inputProps = {
  style: {
    height: 36,
    padding: '5px 14px'
  } 
}

const Toolbar = ({
  className, 
  onSearch, 
  onAddData, 
  producers, 
  categories,
  producerId,
  categoryId,
  onChangeProducerId,
  onChangeCategoryId,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={onAddData}
        >
          Tambah Produk
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          placeholder="Cari produk"
          onChange={onSearch}
        />

        <TextField
          select
          name="producer_id"
          type="text"
          SelectProps={{ native: true }}
          variant="outlined"
          className={classes.selectProducer}
          inputProps={inputProps}
          value={producerId}
          onChange={onChangeProducerId}
        >
          {
            producers.map((item, index) => (
              <option
                key={index}
                value={item.id}
              >
                {item.name}
              </option>
            ))
          }
        </TextField>

        <TextField
          select
          name="category_id"
          type="text"
          SelectProps={{ native: true }}
          variant="outlined"
          className={classes.selectCategory}
          inputProps={inputProps}
          value={categoryId}
          onChange={onChangeCategoryId}
        >
          {
            categories.map((item, index) => (
              <option
                key={index}
                value={item.id}
              >
                {item.name}
              </option>
            ))
          }
        </TextField>
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onAddData: PropTypes.func,
  producers: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  producerId: PropTypes.any,
  categoryId: PropTypes.any,
  onChangeProducerId: PropTypes.func,
  onChangeCategoryId: PropTypes.func
}

export default Toolbar
