
function requestAPI(token) {
  return fetch(`${process.env.REACT_APP_ADMIN_URL}dashboard`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then((res) => res.json())
}

export default function actionGetDashboard() {
  return async (dispatch, getState) => {
    dispatch({
      type: 'GET_DASHBOARD_BEGIN'
    })

    try {
      const token = await getState().auth.authenticate.token
      const result = await requestAPI(token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'GET_DASHBOARD_SUCCESS',
        payload: result.data
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'GET_DASHBOARD_FAILURE',        
    error: error    
  })
}