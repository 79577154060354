import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListVoucherUsed } from '../../store/actions/voucher'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const VoucherListUsed = ({ 
  history,
  match, 
  voucher,
  dispatchListVoucherUsed,
}) => {
  const { code } = match.params
  var delay = null
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [name, setName] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [vouchers, setVouchers] = useState([])

  useEffect(() => {
    if(onState && code) {
      dispatchListVoucherUsed(code, page, limit, name)
    }
  }, [dispatchListVoucherUsed, onState, code])

  useEffect(() => {
    if(voucher.payload) {
      setVouchers(voucher.payload)
      setPage(voucher.page)
      setPages(voucher.pages)
      setOnState(false)
    }
  }, [voucher.payload, voucher.page, voucher.pages])

  const handlePageChange = (event, page) => {
    setPage(page + 1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!voucher.loading) {
      delay = setTimeout(() => {
        setName(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      <ExToolbar
        onSearch={handleSearch}
        onBack={handleBack}
      />
      <div className={classes.content}>
        <ExTable 
          items={vouchers}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </div>
    </div>
  )
}

VoucherListUsed.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    voucher: {
      loading: state.voucher.listVoucherUsed.loading,
      page: state.voucher.listVoucherUsed.page,
      pages: state.voucher.listVoucherUsed.pages,
      payload: state.voucher.listVoucherUsed.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListVoucherUsed: (code, page, limit, name) => dispatch(
      actionListVoucherUsed(code, page, limit, name)
    )
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(VoucherListUsed)
