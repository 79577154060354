import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Modal,
  Typography,
  Backdrop,
  Fade,
  Button
} from '@material-ui/core'
import PropTypes from 'prop-types'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import useClipboard from 'react-use-clipboard'

import { connect } from 'react-redux'
import { actionAlert } from 'store/actions/util'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: 300,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  copyButton: {
    marginLeft: theme.spacing(1)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
}))

const ExModalSuccess = props => {
  const { open, onClose, password, dispatchAlert } = props
  const classes = useStyles()
  const [isCopied, setCopied] = useClipboard(password)
  const [onState, setOnState] = useState(false)

  useEffect(() => {
    if(isCopied && onState) {
      setOnState(false)
      dispatchAlert(true, 'success', 'Password berhasil disalin')
    }
  }, [isCopied, onState, dispatchAlert])

  const handleCopyPassword = () => {
    setCopied()
    setOnState(true)
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            Password Baru Agen SGH
          </Typography>
          <Typography
            variant="subtitle1"
          >
            {password} 
          </Typography>
          <div className={classes.row}>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Tutup
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.copyButton}
              onClick={handleCopyPassword}
            >
              <FileCopyIcon fontSize="small" /> Salin
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

ExModalSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired
}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExModalSuccess)
