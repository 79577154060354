import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, LinearProgress } from '@material-ui/core'

import { connect } from 'react-redux'
import { actionGetAuthenticate } from '../../store/actions/auth'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    paddingTop: '45%',
    textAlign: 'center'
  }
}))

const Splash = ({ authenticate, dispatchGetAuthenticate, handleEndedSplash }) => {
  const classes = useStyles()
  const [completed, setCompleted] = useState(0)
  const [onState, setOnState] = useState(true)
  const [buffer, setBuffer] = useState(10)

  useEffect(() => {
    dispatchGetAuthenticate()
  }, [dispatchGetAuthenticate])

  useEffect(() => {
    if(!authenticate.loading && onState) {
      handleEndedSplash()
      setOnState(false)
    }
  }, [authenticate.loading, onState, handleEndedSplash])

  const progress = useRef(() => {})
  useEffect(() => {
    progress.current = () => {
      if (completed > 100) {
        setCompleted(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 20
        const diff2 = Math.random() * 20
        setCompleted(completed + diff)
        setBuffer(completed + diff + diff2)
      }
    }
  })

  useEffect(() => {
    function tick() {
      progress.current()
    }
    const timer = setInterval(tick, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={4}
          xs={6}
        >
          <div className={classes.content}>
            <LinearProgress 
              variant="buffer" 
              value={completed} 
              valueBuffer={buffer} 
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    handleEndedSplash: () => props.handleEndedSplash(),
    authenticate: {
      loading: state.auth.authenticate.loading,
      status: state.auth.authenticate.status
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchGetAuthenticate: () => dispatch(actionGetAuthenticate())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Splash)
