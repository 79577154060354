import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Modal,
  Typography,
  Backdrop,
  Fade,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { numberFormat } from 'helpers'

import { connect } from 'react-redux'
import { actionAlert } from 'store/actions/util'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  copyButton: {
    marginLeft: theme.spacing(1)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
}))

const ExModalDetail = props => {
  const { open, onClose, transaction } = props
  const classes = useStyles()
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            Detail Transaksi ( {transaction.code} )
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Nama Produk</TableCell>
                <TableCell>Jumlah</TableCell>
                <TableCell>Harga Asli</TableCell>
                <TableCell>Harga Diskon</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              transaction.orders.map((order, index) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={index}
                >
                  <TableCell>{index+1}</TableCell>
                  <TableCell>{order.product.name}</TableCell>
                  <TableCell>{order.quantity}</TableCell>
                  <TableCell>{numberFormat(order.product.price, 'Rp. ')}</TableCell>
                  <TableCell>{numberFormat(order.price, 'Rp. ')}</TableCell>
                </TableRow>
              ))
            }
            </TableBody>
          </Table>
          <br/>
          <Typography
            variant="h5"
          >
            Penerima: 
          </Typography>
          <Typography
            variant="subtitle1"
            style={{whiteSpace: "pre-line"}}
          >
            {transaction.recipient.detail}
          </Typography>
          <br/>
          <Typography
            variant="h5"
          >
            Pengirim: 
          </Typography>
          <Typography
            variant="subtitle1"
            style={{whiteSpace: "pre-line"}}
          >
            {transaction.sender.detail}
          </Typography>
          <br/>
          <Typography
            variant="h5"
          >
            Total {numberFormat(transaction.total, 'Rp. ')}
          </Typography>
          <div className={classes.row}>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Tutup
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

ExModalDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired
}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExModalDetail)
