import actionGetAccount from './actionGetAccount'
import actionGetAuthenticate from './actionGetAuthenticate'

function requestAPI(data) {
  return fetch(`${process.env.REACT_APP_ADMIN_URL}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())
}

export default function actionLogin(data) {
  return async (dispatch) => { 
    dispatch({
      type: 'AUTH_LOGIN_BEGIN'
    })

    try {
      const result = await requestAPI(data)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      await Promise.all([
        storeTokenData(result.data), 
        dispatch(actionGetAccount(result.data))
      ])
      dispatch(actionGetAuthenticate())
      
      dispatch({        
        type: 'AUTH_LOGIN_SUCCESS'
      })
    } catch(err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'AUTH_LOGIN_FAILURE',        
    error: error    
  })
}

function storeTokenData(token) {
  return localStorage.setItem('token', token)
}