import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'
import { encodeParams } from '../../helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListProducer, actionDeleteProducer } from '../../store/actions/producer'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const ProducerList = ({ history, producer, deleteProducer, dispatchListProducer, dispatchDeleteProducer }) => {
  var delay = null
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [name, setName] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [producers, setProducers] = useState([])

  useEffect(() => {
    if(onState) {
      dispatchListProducer(page, limit, name)
    }
  }, [dispatchListProducer, onState, page, limit, name])

  useEffect(() => {
    if(producer.payload) {
      setProducers(producer.payload)
      setPage(producer.page)
      setPages(producer.pages)
      setOnState(false)
    }
  }, [producer.payload, producer.page, producer.pages])

  useEffect(() => {
    if(deleteProducer.success) {
      setOnState(true)
    }
  }, [deleteProducer.success])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!producer.loading) {
      delay = setTimeout(() => {
        setName(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  const handleAddData = () => {
    history.push('/producer/create')
  }

  const handleUpdateData = (data) => {
    history.push(`/producer/update/${encodeParams(data)}`)
  }

  const handleDeleteData = (id) => {
    dispatchDeleteProducer(id)
    if(producers.length === 1) {
      setPage((page > 1)? page - 1 : 1)
    }
  }

  return (
    <div className={classes.root}>
      <ExToolbar onSearch={handleSearch} onAddData={handleAddData} />
      <div className={classes.content}>
        <ExTable 
          producers={producers}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDeleteData={handleDeleteData}
          onUpdateData={handleUpdateData}
        />
      </div>
    </div>
  )
}

ProducerList.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    producer: {
      loading: state.producer.listProducer.loading,
      page: state.producer.listProducer.page,
      pages: state.producer.listProducer.pages,
      payload: state.producer.listProducer.payload
    },
    deleteProducer: {
      success: state.producer.deleteProducer.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListProducer: (page, limit, name) => dispatch(actionListProducer(page, limit, name)),
    dispatchDeleteProducer: (id) => dispatch(actionDeleteProducer(id))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProducerList)
