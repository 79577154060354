import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import ImageIcon from '@material-ui/icons/Image'
import AccessibilityIcon from '@material-ui/icons/Accessibility'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'

import { Profile, SidebarNav } from './components'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}))

const Sidebar = props => {
  const { open, variant, onClose, className, account, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Agen Dropshipping',
      href: '/agent-dropshipping',
      icon: <PeopleIcon />
    },
    {
      title: 'Agen SGH',
      href: '/agent-sgh',
      icon: <PeopleIcon />
    },
    {
      title: 'Agen Herbal',
      href: '/agent',
      icon: <PeopleIcon />
    },
    {
      title: 'Produsen',
      href: '/producer',
      icon: <AccessibilityIcon />
    },
    {
      title: 'Kategori',
      href: '/category',
      icon: <MenuBookIcon />
    },
    {
      title: 'Banner',
      href: '/banner',
      icon: <ImageIcon />
    },
    {
      title: 'Produk',
      href: '/product',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Voucher',
      href: '/voucher',
      icon: <ConfirmationNumberIcon />
    },
    {
      title: 'Transaksi Dropshipping',
      href: '/transaction-dropshipping',
      icon: <ShoppingCartIcon />
    },
    {
      title: 'Transaksi SGH',
      href: '/transaction-sgh',
      icon: <ShoppingCartIcon />
    },
    {
      title: 'Transaksi Herbal',
      href: '/transaction',
      icon: <ShoppingCartIcon />
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile account={account} />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired
}

export default Sidebar
