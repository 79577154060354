import 'date-fns'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import { 
  MuiPickersUtilsProvider,
  KeyboardDatePicker 
} from '@material-ui/pickers'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { SearchInput } from 'components'
import { numberFormat } from 'helpers'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  date: {
    backgroundColor: '#FFF',
    marginLeft: theme.spacing(2)
  },
  total: {
    marginLeft: theme.spacing(10),
    fontSize: theme.spacing(4),
    backgroundColor: '#FFF',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const Toolbar = ({ 
  className, 
  onSearch, 
  startDate, 
  endDate, 
  onChangeStartDate,
  onChangeEndDate,
  onClearDate,
  sum,
  ...rest 
}) => {
  const classes = useStyles()

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <SearchInput
          placeholder="Cari transaksi"
          onChange={onSearch}
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.date}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="start-date"
              label="Tanggal Mulai"
              value={startDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              error={false}
              helperText={null}
              onChange={onChangeStartDate}
            />
          </div>

          <div className={classes.date}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              id="end-date"
              label="Tanggal Akhir"
              value={endDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              error={false}
              helperText={null}
              onChange={onChangeEndDate}
            />
          </div>
          <div className={classes.date}>
            <IconButton 
              onClick={onClearDate}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </MuiPickersUtilsProvider>
          <div className={classes.total}>
            {numberFormat(sum.toFixed(2), 'Rp. ')}
          </div>
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
  onClearDate: PropTypes.func,
  sum: PropTypes.number
}

export default Toolbar
