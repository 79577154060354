const initialState = { 
  loading: false,
  success: null,
  error: null
}

function updateProducerReducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_PRODUCER_BEGIN':
      return {
        ...state,
        loading: true,
        success: null,
        error: null
      }

    case 'UPDATE_PRODUCER_SUCCESS':
      return {
        ...state,
        loading: false,
        success: action.success
      }

    case 'UPDATE_PRODUCER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }

    default: return state
  }
}

export default updateProducerReducer