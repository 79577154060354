import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
  InputAdornment
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionSaveProduct } from '../../store/actions/product'
import { actionListProducer } from '../../store/actions/producer'
import { actionListCategory } from '../../store/actions/category'

import { decodeFile } from '../../helpers'
import { ExNumberFormat } from '../../components'

const schema = {
  name: {
    presence: { 
      allowEmpty: false, 
      message: 'Nama harus diisi' 
    }
  },
  price: {
    presence: { 
      allowEmpty: false, 
      message: 'Harga harus diisi' 
    }
  },
  weight: {
    presence: { 
      allowEmpty: false, 
      message: 'Berat harus diisi beserta satuan' 
    }
  },
  amount: {
    presence: { 
      allowEmpty: false, 
      message: 'Jumlah per Karton harus diisi' 
    }
  },
  producer_id: {
    presence: { 
      allowEmpty: false, 
      message: 'Produsen harus diisi' 
    },
    numericality: { 
      onlyInteger: true, 
      notValid: 'Produsen harus berupa angka' 
    }
  },
  category_id: {
    presence: { 
      allowEmpty: false, 
      message: 'Kategori harus diisi' 
    },
    numericality: { 
      onlyInteger: true, 
      notValid: 'Kategori harus berupa angka' 
    }
  },
  sgh: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon grade SGH harus diisi' 
    }
  },
  grade_aplus: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon grade A+ harus diisi' 
    }
  },
  grade_a: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon grade A harus diisi' 
    }
  },
  grade_bplus: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon grade B+ harus diisi' 
    }
  },
  grade_b: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon grade B harus diisi' 
    }
  },
  grade_c: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon grade C harus diisi' 
    }
  },
  retail: {
    presence: { 
      allowEmpty: false, 
      message: 'Diskon eceran harus diisi' 
    }
  },
  description: {
    presence: { 
      allowEmpty: false, 
      message: 'Deskripsi harus diisi' 
    }
  },
  filename: {
    presence: { 
      allowEmpty: false, 
      message: 'Gambar harus diisi' 
    }
  },
  image: {
    presence: { 
      allowEmpty: false, 
      message: 'Gambar harus diisi'
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  grid: {
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 800,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  textFile: {
    display: 'none'
  },
  iconFile: {
    cursor: 'pointer'
  },
  createButton: {
    margin: theme.spacing(2, 0)
  },
  imageContainer: {
    marginTop: theme.spacing(2),
    height: '100%',
    width: '60%',
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  }
}))

const ProductCreate = ({ 
  history, 
  saveProduct, 
  producer, 
  category, 
  dispatchSaveProduct, 
  dispatchListCategory, 
  dispatchListProducer 
}) => {
  const limit = 1000
  const page = 1
  const classes = useStyles()
  const [onState, setOnState] = useState(false)
  const fileRef = useRef(null)
  const [base64, setBase64] = useState('')
  const [producers, setProducers] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    dispatchListProducer(page, limit, '')
    dispatchListCategory(page, limit, '')
  }, [dispatchListProducer, dispatchListCategory, page, limit])

  useEffect(() => {
    if(producer.payload) {
      setProducers(producer.payload)
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          producer_id: (producer.payload[0])? producer.payload[0].id : ''
        },
        touched: {
          ...formState.touched,
          producer_id: true
        }
      }))
    }
  }, [producer.payload])

  useEffect(() => {
    if(category.payload) {
      setCategories(category.payload)
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          category_id: (category.payload[0])? category.payload[0].id : ''
        },
        touched: {
          ...formState.touched,
          category_id: true
        }
      }))
    }
  }, [category.payload])

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      producer_id: 5
    },
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  useEffect(() => {
    if(saveProduct.success && onState) {
      setOnState(false)
      history.goBack()
    }
  }, [saveProduct.success, onState, history])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleChangeNumber = event => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleChangeFile = async (event) => {
    event.persist()
    const file = await decodeFile(event.target.files[0])
    
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        image: file.decode,
        filename: file.name
      },
      touched: {
        ...formState.touched,
        image: file.decode,
        filename: file.name
      }
    }))
    setBase64(file.base64)
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleProductCreate = event => {
    event.preventDefault()
    const formValues = formState.values
    formValues.discount = {
      sgh: formValues.sgh,
      grade_aplus: formValues.grade_aplus,
      grade_a: formValues.grade_a,
      grade_bplus: formValues.grade_bplus,
      grade_b: formValues.grade_b,
      grade_c: formValues.grade_c,
      retail: formValues.retail
    }
    dispatchSaveProduct(formValues)
    setOnState(true)
  }

  const handlePickFile = event => {
    fileRef.current.click()
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleProductCreate}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  Tambah Produk
                </Typography>
                <Typography
                  color="textSecondary"
                  gutterBottom
                >
                  Semua inputan wajib diisi
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('name')}
                  fullWidth
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  label="Nama Produk"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('price')}
                  fullWidth
                  helperText={
                    hasError('price') ? formState.errors.price[0] : null
                  }
                  label="Harga Produk"
                  name="price"
                  onChange={handleChangeNumber}
                  value={formState.values.price || ''}
                  variant="outlined"
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    startAdornment: (
                      <InputAdornment position="start">Rp. </InputAdornment>
                    )
                  }}
                />
                <TextField
                  className={classes.textField}
                  error={hasError('weight')}
                  fullWidth
                  helperText={
                    hasError('weight') ? formState.errors.weight[0] : null
                  }
                  label="Berat Produk"
                  name="weight"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.weight || ''}
                  variant="outlined"
                />
                
                <TextField
                  className={classes.textField}
                  error={hasError('amount')}
                  fullWidth
                  helperText={
                    hasError('amount') ? formState.errors.amount[0] : null
                  }
                  label="Jumlah per Karton"
                  name="amount"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat
                  }}
                  type="text"
                  value={formState.values.amount || ''}
                  variant="outlined"
                />
                {
                  (!producer.loading)
                  ? <TextField
                      className={classes.textField}
                      error={hasError('producer_id')}
                      fullWidth
                      select
                      helperText={
                        hasError('producer_id') ? formState.errors.producer_id[0] : null
                      }
                      label="Produsen Produk"
                      name="producer_id"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.producer_id}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {
                        producers.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                          >
                            {option.name}
                          </option>
                        ))
                      }
                    </TextField>
                  : null
                }
                {
                  (!category.loading)
                  ? <TextField
                      className={classes.textField}
                      error={hasError('category_id')}
                      fullWidth
                      select
                      helperText={
                        hasError('category_id') ? formState.errors.category_id[0] : null
                      }
                      label="Kategori Produk"
                      name="category_id"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.category_id}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {
                        categories.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                          >
                            {option.name}
                          </option>
                        ))
                      }
                    </TextField>
                  : null
                }
                <TextField
                  className={classes.textField}
                  error={hasError('sgh')}
                  fullWidth
                  helperText={
                    hasError('sgh') ? formState.errors.sgh[0] : null
                  }
                  label="Diskon SGH"
                  name="sgh"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.sgh || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('grade_aplus')}
                  fullWidth
                  helperText={
                    hasError('grade_aplus') ? formState.errors.grade_aplus[0] : null
                  }
                  label="Diskon Grade A+"
                  name="grade_aplus"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.grade_aplus || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('grade_a')}
                  fullWidth
                  helperText={
                    hasError('grade_a') ? formState.errors.grade_a[0] : null
                  }
                  label="Diskon Grade A"
                  name="grade_a"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.grade_a || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('grade_bplus')}
                  fullWidth
                  helperText={
                    hasError('grade_bplus') ? formState.errors.grade_bplus[0] : null
                  }
                  label="Diskon Grade B+"
                  name="grade_bplus"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.grade_bplus || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('grade_b')}
                  fullWidth
                  helperText={
                    hasError('grade_b') ? formState.errors.grade_b[0] : null
                  }
                  label="Diskon Grade B"
                  name="grade_b"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.grade_b || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('grade_c')}
                  fullWidth
                  helperText={
                    hasError('grade_c') ? formState.errors.grade_c[0] : null
                  }
                  label="Diskon Grade C"
                  name="grade_c"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.grade_c || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('retail')}
                  fullWidth
                  helperText={
                    hasError('retail') ? formState.errors.retail[0] : null
                  }
                  label="Diskon Eceran"
                  name="retail"
                  onChange={handleChangeNumber}
                  InputProps={{
                    inputComponent: ExNumberFormat,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                  type="text"
                  value={formState.values.retail || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('description')}
                  fullWidth
                  helperText={
                    hasError('description') ? formState.errors.description[0] : null
                  }
                  label="Deskripsi Produk"
                  name="description"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.description || ''}
                  variant="outlined"
                  multiline={true}
                  rows={10}
                />
                <input 
                  accept="image/*" 
                  className={classes.textFile}
                  type="file"
                  ref={fileRef}
                  name="image"
                  onChange={handleChangeFile}
                />  
                <TextField
                  className={classes.textField}
                  error={hasError('filename')}
                  fullWidth
                  helperText={
                    hasError('filename') ? formState.errors.filename[0] : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment 
                        className={classes.iconFile} 
                        position="start"
                      >
                        <AddPhotoAlternateIcon />
                      </InputAdornment>
                    ),
                  }}
                  label="Gambar Product"
                  placeholder="Pilih Gambar"
                  name="filename"
                  type="text"
                  onClick={handlePickFile}
                  value={formState.values.filename || ''}
                  variant="outlined"
                  disabled
                />
                {
                  (base64)
                  ? <div className={classes.imageContainer}>
                      <img 
                        className={classes.image}
                        alt="Gambar Product"
                        src={base64}
                      />
                    </div>
                  : null
                }
                <Button
                  className={classes.createButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Simpan
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ProductCreate.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    saveProduct: {
      loading: state.product.saveProduct.loading,
      success: state.product.saveProduct.success
    },
    producer: {
      loading: state.producer.listProducer.loading,
      payload: state.producer.listProducer.payload
    },
    category: {
      loading: state.category.listCategory.loading,
      payload: state.category.listCategory.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchSaveProduct: (data) => dispatch(actionSaveProduct(data)),
    dispatchListCategory: (page, limit, name) => dispatch(actionListCategory(page, limit, name)),
    dispatchListProducer: (page, limit, name) => dispatch(actionListProducer(page, limit, name))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductCreate)