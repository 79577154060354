import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton
} from '@material-ui/core'

import { ExModalDelete } from 'components'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  updateButton: {
    marginRight: theme.spacing(1)
  },
  imageContainer: {
    height: 96,
    width: 96,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  }
}))

const ExTable = props => {
  const { 
    className, 
    categories, 
    page, 
    pages, 
    limit, 
    onChangePage, 
    onChangeRowsPerPage, 
    onDeleteData, 
    onUpdateData, 
    ...rest 
  } = props

  const [modal, setModal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const classes = useStyles()

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleOpenModal = (id) => {
    setSelectedId(id)
    setModal(true)
  }

  const handleDeleteModal = () => {
    onDeleteData(selectedId)
    setModal(false)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Nama</TableCell>
                  <TableCell>Urutan</TableCell>
                  <TableCell>Gambar</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={category.id}
                  >
                    <TableCell>{((page*limit)-limit) + (index+1)}</TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.sort}</TableCell>
                    <TableCell>
                      <div className={classes.imageContainer}>
                        <img
                          key={category.image}
                          alt={category.name}
                          className={classes.image}
                          src={process.env.REACT_APP_IMAGE_URL + category.image}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className={classes.updateButton}
                        color="inherit"
                        onClick={() => onUpdateData(category)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        onClick={() => handleOpenModal(category.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={pages * limit}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page-1}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
      
      <ExModalDelete 
        open={modal} 
        onClose={handleCloseModal} 
        onDelete={handleDeleteModal}
      />
    </Card>
  )
}

ExTable.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDeleteData: PropTypes.func.isRequired,
  onUpdateData: PropTypes.func.isRequired
}

export default ExTable
