import { combineReducers } from 'redux'

import listAgent from './listAgent'
import saveAgent from './saveAgent'
import updateAgent from './updateAgent'
import deleteAgent from './deleteAgent'
import resetPasswordAgent from './resetPasswordAgent'

export default combineReducers({
  listAgent: listAgent,
  saveAgent: saveAgent,
  updateAgent: updateAgent,
  deleteAgent: deleteAgent,
  resetPasswordAgent: resetPasswordAgent
})
