
export default (number, discount, prefix) => {
  let currency = number.split('.')
  if (discount) {
    currency[0] = parseFloat(currency[0]) - parseFloat(discount.value)
  }

  return (prefix)
    ? prefix + currency[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : currency[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
