import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

import { IconButton } from '@material-ui/core'
import { SearchInput } from 'components'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  contentHeader: {

  }
}))

const Toolbar = ({ className, onSearch, onBack, ...rest }) => {
  const classes = useStyles()

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <div className={classes.contentHeader}>
          <IconButton onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <span className={classes.spacer} />
        <SearchInput
          placeholder="Cari Agen"
          onChange={onSearch}
        />
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func,
  onBack: PropTypes.func,
}

export default Toolbar
