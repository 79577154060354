import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Modal, 
  Typography, 
  Backdrop, 
  Fade,
  Button
} from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 2),
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  deleteButton: {
    marginLeft: theme.spacing(1)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
}))

const ExModalDelete = props => {
  const { open, onClose, onDelete  } = props
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography
            className={classes.title}
            variant="h4"
          >
            Konfirmasi Hapus
          </Typography>
          <Typography
            variant="subtitle1"
          >
            Apakah anda yakin menghapus data ini?
          </Typography>
          <div className={classes.row}>
            <span className={classes.spacer} />
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Batal
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.deleteButton}
              onClick={onDelete}
            >
              Hapus
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

ExModalDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default ExModalDelete
