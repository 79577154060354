import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'
import { encodeParams } from '../../helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { 
  actionListAgent, 
  actionDeleteAgent, 
  actionResetPasswordAgent 
} from '../../store/actions/agentSgh'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const AgentListSGH = ({ 
  history, 
  agent, 
  deleteAgent, 
  dispatchListAgent, 
  dispatchDeleteAgent,
  dispatchResetPasswordAgent,
  resetPasswordAgent
}) => {
  var delay = null
  const classes = useStyles()
  const [onResetState, setOnResetState] = useState(false)
  const [onState, setOnState] = useState(true)
  const [modalResetPassword, setModalResetPassword] = useState(false)
  const [name, setName] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [agents, setAgents] = useState([])
  const [password, setPassword] = useState('')

  useEffect(() => {
    if(onState) {
      dispatchListAgent(page, limit, name)
    }
  }, [dispatchListAgent, onState, page, limit, name])

  useEffect(() => {
    if(agent.payload) {
      setAgents(agent.payload)
      setPage(agent.page)
      setPages(agent.pages)
      setOnState(false)
    }
  }, [agent.payload, agent.page, agent.pages])

  useEffect(() => {
    if(deleteAgent.success) {
      setOnState(true)
    }
  }, [deleteAgent.success])

  useEffect(() => {
    if(resetPasswordAgent.success && onResetState) {
      setPassword(resetPasswordAgent.success.password)
      setModalResetPassword(true)
      setOnResetState(false)
    }
  }, [resetPasswordAgent, onResetState])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!agent.loading) {
      delay = setTimeout(() => {
        setName(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  const handleAddData = () => {
    history.push('/agent-sgh/create')
  }

  const handleUpdateData = (data) => {
    history.push(`/agent-sgh/update/${encodeParams(data)}`)
  }

  const handleDeleteData = (id) => {
    dispatchDeleteAgent(id)
    if(agents.length === 1) {
      setPage((page > 1)? page - 1 : 1)
    }
  }

  const handleResetPasswordData = (id) => {
    dispatchResetPasswordAgent(id)
    setOnResetState(true)
  }

  const handleCloseModalResetPassword = () => {
    setModalResetPassword(false)
  }

  return (
    <div className={classes.root}>
      <ExToolbar onSearch={handleSearch} onAddData={handleAddData} />
      <div className={classes.content}>
        <ExTable 
          agents={agents}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onDeleteData={handleDeleteData}
          onUpdateData={handleUpdateData}
          onResetPasswordData={handleResetPasswordData}
          modalResetPassword={modalResetPassword}
          onCloseModalResetPassword={handleCloseModalResetPassword}
          password={password}
        />
      </div>
    </div>
  )
}

AgentListSGH.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    agent: {
      loading: state.agentSgh.listAgent.loading,
      page: state.agentSgh.listAgent.page,
      pages: state.agentSgh.listAgent.pages,
      payload: state.agentSgh.listAgent.payload
    },
    deleteAgent: {
      success: state.agentSgh.deleteAgent.success
    },
    resetPasswordAgent: {
      success: state.agentSgh.resetPasswordAgent.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListAgent: (page, limit, name) => dispatch(actionListAgent(page, limit, name)),
    dispatchDeleteAgent: (id) => dispatch(actionDeleteAgent(id)),
    dispatchResetPasswordAgent: (id) => dispatch(actionResetPasswordAgent(id))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AgentListSGH)
