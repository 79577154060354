
export default {

  banner:[
    {
      id: 'herbalkatalog',
      name: 'Herbal Katalog'
    },
    {
      id: 'supergrosir',
      name: 'Super Grosir Herbal'
    },
    {
      id: 'dropshipping',
      name: 'Dropshipping Herbal'
    }
  ],
  agentDropshipping:[
    {
      id: 'grade_b',
      name: 'Agen'
    },
    {
      id: 'grade_c',
      name: 'Non Agen'
    }
  ],
  agentSgh:[
    {
      id: 'sgh',
      name: 'SGH'
    }
  ],
  agent:[
    {
      id: 'grade_aplus',
      name: 'Grade A+'
    },
    {
      id: 'grade_a',
      name: 'Grade A'
    },
    {
      id: 'grade_bplus',
      name: 'Grade B+'
    },
    {
      id: 'grade_b',
      name: 'Grade B'
    },
    {
      id: 'grade_c',
      name: 'Grade C'
    }
  ]
}
