
function requestAPI(data, token) {
  return fetch(`${process.env.REACT_APP_ADMIN_URL}banner`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(data)
  }).then((res) => res.json())
}

export default function actionSaveBanner(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'SAVE_BANNER_BEGIN'
    })

    try {
      const token = await getState().auth.authenticate.token
      const result = await requestAPI(data, token)
      if(!result.status) {
        handleErrors(dispatch, result)
        return
      }

      dispatch({
        type: 'SAVE_BANNER_SUCCESS',
        success: result
      })
    } catch (err) {
      handleErrors(dispatch, err)
    }
  }
}

function handleErrors(dispatch, error) {
  dispatch({        
    type: 'SAVE_BANNER_FAILURE',        
    error: error    
  })
}