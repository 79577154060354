import { combineReducers } from 'redux'

import listCategory from './listCategory'
import saveCategory from './saveCategory'
import updateCategory from './updateCategory'
import deleteCategory from './deleteCategory'

export default combineReducers({
  listCategory: listCategory,
  saveCategory: saveCategory,
  updateCategory: updateCategory,
  deleteCategory: deleteCategory
})
