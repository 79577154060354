import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ExToolbar, ExTable } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListTransaction, actionFinishTransaction } from '../../store/actions/transactionDropshipping'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const TransactionListDropshipping = ({
  transaction, 
  finishTransaction,
  dispatchListTransaction,
  dispatchFinishTransaction
}) => {
  var delay = null
  const classes = useStyles()
  const [onState, setOnState] = useState(true)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [code, setCode] = useState('')
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    if(onState) {
      dispatchListTransaction(page, limit, code, startDate, endDate)
    }
  }, [dispatchListTransaction, onState, page, limit, code, startDate, endDate])

  useEffect(() => {
    if(transaction.payload) {
      setTransactions(transaction.payload)
      setPage(transaction.page)
      setPages(transaction.pages)
      setOnState(false)
    }
  }, [transaction.payload, transaction.page, transaction.pages])

  const handlePageChange = (event, page) => {
    setPage(page+1)
    setOnState(true)
  }

  const handleRowsPerPageChange = event => {
    setPage(1)
    setLimit(event.target.value)
    setOnState(true)
  }

  const handleSearch = (event) => {
    const terms = event.target.value
    if(delay) clearTimeout(delay)

    if(!transaction.loading) {
      delay = setTimeout(() => {
        setCode(terms)
        setPage(1)
        setOnState(true)
      }, 600)
    }
  }

  useEffect(() => {
    if(finishTransaction.success) {
      setOnState(true)
    }
  }, [finishTransaction.success])

  const hanndleFinishData = (id) => {
    dispatchFinishTransaction(id)
  }

  const handleChangeStartDate = (value) => {
    if(endDate && endDate < value) {
      setEndDate(value)
      setStartDate(value)
      setOnState(true)
      return
    }

    setStartDate(value)
    setOnState(true)
  }

  const handleChangeEndDate = (value) => {
    if(startDate && startDate > value) {
      setStartDate(value)
      setEndDate(value)
      setOnState(true)
      return
    }

    setEndDate(value)
    setOnState(true)
  }

  const handleClearDate = () => {
    setStartDate(null)
    setEndDate(null)
    setOnState(true)
  }

  return (
    <div className={classes.root}>
      <ExToolbar 
        onSearch={handleSearch} 
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={handleChangeStartDate}
        onChangeEndDate={handleChangeEndDate}
        onClearDate={handleClearDate}
        sum={transaction.sum}
      />

      <div className={classes.content}>
        <ExTable 
          transactions={transactions}
          page={page} 
          pages={pages} 
          limit={limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          onFinishData={hanndleFinishData}
        />
      </div>
    </div>
  )
}

TransactionListDropshipping.propTypes = {
  history: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    transaction: {
      loading: state.transactionDropshipping.listTransaction.loading,
      page: state.transactionDropshipping.listTransaction.page,
      pages: state.transactionDropshipping.listTransaction.pages,
      sum: state.transactionDropshipping.listTransaction.sum,
      payload: state.transactionDropshipping.listTransaction.payload
    },
    finishTransaction: {
      success: state.transaction.finishTransaction.success
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListTransaction: (page, limit, code, startDate, endDate) => dispatch(actionListTransaction(page, limit, code, startDate, endDate)),
    dispatchFinishTransaction: (transactionId) => dispatch(actionFinishTransaction(transactionId))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TransactionListDropshipping)
